import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import WindNightSkyBackground from '../../components/NightSkyBackgound/WindNightSkyBackground';
import './Games.css'

function Games () {
   /* const handleClick = () => {
        
    } */

    return (
        <div className='Game-Page'>
        <WindNightSkyBackground />
        <Header />
        <div id='Game-main'>
        
        </div>
        <Footer />
        </div>
    )
};

export default Games;