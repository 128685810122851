import React, { useEffect, useRef } from 'react';

const WaterNightSkyBackground = () => {
  const canvasRef = useRef(null);

  class Star {
    constructor(x, y, size, brightness) {
      this.x = x;
      this.y = y;
      this.size = size;
      this.brightness = brightness;
      this.maxBrightness = brightness;
      this.speed = 0.05 + Math.random() * 0.05;
      this.angle = Math.random() * Math.PI * 2;
    }

    update() {
      this.angle += this.speed;
      this.brightness = this.maxBrightness * (0.5 + Math.sin(this.angle) * 0.5);
    }

    draw(ctx) {
      ctx.fillStyle = `rgba(255, 255, 255, ${this.brightness})`;
      ctx.beginPath();
      ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
      ctx.fill();
    }
  }

  useEffect(() => {
    const canvas = canvasRef.current;    
    const ctx = canvas.getContext('2d');
    
    const stars = [];
    let animationFrameId;
    
    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    
    const initStars = () => {
      const starRegionHeight = canvas.height * 0.4;
      const numberOfStars = Math.floor((canvas.width * starRegionHeight) / 2000);
      
      for (let i = 0; i < numberOfStars; i++) {
        const x = Math.random() * canvas.width;
        const y = Math.random() * starRegionHeight;
        const size = 0.5 + Math.random() * 1.5;
        const brightness = 0.2 + Math.random() * 0.8;
        stars.push(new Star(x, y, size, brightness));
      }
    };

    const drawWaves = () => {
     
      const waveStartY = canvas.height * 0.45;
      const waveHeight = 3; 
      const numberOfWaves = 8; 
      const waveSpacing = (canvas.height - waveStartY) / numberOfWaves;

      for (let wave = 0; wave < numberOfWaves; wave++) {
        ctx.beginPath();
        const opacity = 0.1 + (wave / numberOfWaves) * 0.3;
        ctx.strokeStyle = `rgba(12, 192, 223, ${opacity})`;
        ctx.lineWidth = 1 + wave * 0.5; 
        const baseY = waveStartY + (wave * waveSpacing);
        
        for (let x = 0; x < canvas.width; x += 1) {
          
          const frequency = 0.02 - (wave * 0.001);
          const amplitude = waveHeight * (1 + wave * 0.5);
          const y = baseY + 
                    Math.sin(x * frequency + wave + performance.now() * 0.001) * amplitude;
          
          if (x === 0) {
            ctx.moveTo(x, y);
          } else {
            ctx.lineTo(x, y);
          }
        }
        ctx.stroke();
      }
    };

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
      gradient.addColorStop(0, '#292929');
      gradient.addColorStop(0.4, '#292929');
      gradient.addColorStop(1, '#0cc0df');
      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      stars.forEach(star => {
        star.update();
        star.draw(ctx);
      });

      drawWaves();

      animationFrameId = requestAnimationFrame(animate);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    initStars();
    animate();

    return () => {
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(animationFrameId);
    };
  }, );

  return (
    <canvas ref={canvasRef} className="fixed top-0 left-0 w-full h-full z-20" />
  );
};

export default WaterNightSkyBackground;