import React, { useState, useEffect, useRef } from 'react';
import { Menu } from 'lucide-react';
import logo from '../../assets/img/Unauth_Logo_Dark_Mode.png';
import './Header.css';

const Header = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="header">
      <div className="header-container">
        <div className="logo">
         <a href='/'> <img src={logo} alt="Logo" /> </a>
        </div>
        
        <h1 className="business-name">Unauthorized Studios</h1>
        
        <div className="dropdown-container" ref={dropdownRef}>
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="menu-button"
          >
            <Menu size={24} />
          </button>
          
          {isDropdownOpen && (
            <div className="dropdown-menu">
              <a href="/" className="dropdown-item">Home</a>
              <a href="/about" className="dropdown-item">About</a>
              <a href="/games" className="dropdown-item">Games</a>
              <a href="/projects" className="dropdown-item">Web Design</a>
              <a href="/contact" className="dropdown-item">Contact</a>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;