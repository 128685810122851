import React from 'react';
import logo from '../../assets/img/Unauthorized.png';
import './Footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-left">
          <div className="footer-logo">
            
            <img src={logo} alt="Logo" />
          </div>
          <p className="copyright">© {currentYear} Unauthorized Studios LLC. All rights reserved.</p>
        </div>
        <div className="footer-right">
          <nav className="footer-nav">
            <a href="/" className="footer-link">Home</a>
            <a href="/" className="footer-link">About</a>
            <a href="/" className='footer-link'>Games</a>
            <a href="/" className="footer-link">Web Design</a>
            <a href="/" className="footer-link">Templates</a>
            <a href="/contact" className="footer-link">Contact</a>
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
