// import HomeMain from '../../components/Home-Main/Home-Main';
// import Header from '../../components/Header/Header';
 import UnderConstruction from '../../components/UnderConstruction/UnderConstruction';
// import Footer from '../../components/Footer/Footer';
import './Home.css';

function Home() {
  return (
   /*<div className="Home">
      <Header />
      <main className="Home-Main-Content">
        <HomeMain />
      </main>
      <Footer />
     </div>   */
     <div className='underConstruction'>
      <UnderConstruction />
    </div> 
  );
}

export default Home;
