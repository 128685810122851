import React from 'react';
import Logo from '../../assets/img/Unauth_Logo_Dark_Mode.png';
//import Footer from '../Footer/Footer';
import './UnderConstruction.css';


const UnderConstruction = () => {
  return (
    <div className="under-construction">
        <div className="Logo">
          <img className="UAC-Logo" src={Logo} alt='UAC Logo' />
        </div>
      <div className="content">
        <h1>Site Under Construction</h1>
        <div className="loader">
          <div className="gear-container">
            <div className="gear-rotate"></div>
          </div>
        </div>
        <p>I'm working hard to bring you something awesome. Please check back soon!</p>
        <p>In the mean time, <a href='/contact'>click here</a> to access the contact form</p>
      </div>
      <div className="Logo">
          <img className="UAC-Logo" src={Logo} alt='UAC Logo' />
        </div>
      
    </div>
  );
};

export default UnderConstruction;