import React from 'react';
import Header from '../..//components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './Projects.css'

function Projects () {
    
    return (
        <div className="Projects-Page">
            <Header />
            <div className="Projects-Main">

            </div>
            <Footer />
        </div>
    )
};

export default Projects;