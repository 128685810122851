import React from 'react';
import profilePicture from '../../assets/img/about_img.jpg';
import WaterNightSkyBackground from '../NightSkyBackgound/WaterNightSkyBackground';
import './About-Main.css';


const AboutMain = () => {
    const picture = profilePicture;


    return (
        <div className='About-Page'>
            <WaterNightSkyBackground />
            <div className='About-Picture'>
                <img src={picture} alt='Unauthorized Studios Logo' />
            </div>
            <div className='About-Content-Wrapper'>
                <div className='About-Header'>
                    <h1>Well, Hello There!</h1>
                </div>
                <div className='About-Content'>
                    <p>
                        My name is Jeffrey Renk and I'm the owner and operator of Unauthorized Studios LLC. a website and video game design and development studio. My passion for video games as a child blossomed into a passion for computer science and coding, and in that growth, came a love for website deveolpment. Over the years, I've grown my knowledge from making simple games using tools like Unity and Gamemaker Studio, to creating full-stack web apps using HTML5, CSS3, JavaScript/React, and Node.js, a text-based game using React, Node.js and PostgreSQL for database management, and a current production (TBA) using Gamemaker Studio 2. If you're looking for an enterprise grade site at small business prices then check out the <a href='/contact' className='contact-link'>Contact Page</a> to get authorized today!
                    </p>
                </div>
            </div>
        </div>
    )
}

export default AboutMain;