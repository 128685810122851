import React, { useEffect, useRef } from 'react';

const WindNightSkyBackground = () => {
  const canvasRef = useRef(null);

  class Star {
    constructor(x, y, size, brightness) {
      this.x = x;
      this.y = y;
      this.size = size;
      this.brightness = brightness;
      this.maxBrightness = brightness;
      this.speed = 0.05 + Math.random() * 0.05;
      this.angle = Math.random() * Math.PI * 2;
    }

    update() {
      this.angle += this.speed;
      this.brightness = this.maxBrightness * (0.5 + Math.sin(this.angle) * 0.5);
    }

    draw(ctx) {
      ctx.fillStyle = `rgba(255, 255, 255, ${this.brightness})`;
      ctx.beginPath();
      ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
      ctx.fill();
    }
  }

  class WindLine {
    constructor(x, y, length, speed) {
      this.x = x;
      this.y = y;
      this.length = length;
      this.speed = speed;
      this.opacity = 0.1 + Math.random() * 0.2;
    }

    update() {
      this.x += this.speed;
      if (this.x > window.innerWidth + this.length) {
        this.x = -this.length;
        this.y = window.innerHeight * 0.4 + Math.random() * (window.innerHeight * 0.6);
        this.opacity = 0.1 + Math.random() * 0.2;
      }
    }

    draw(ctx) {
      ctx.strokeStyle = `rgba(255, 255, 255, ${this.opacity})`;
      ctx.lineWidth = 1;
      ctx.beginPath();
      ctx.moveTo(this.x, this.y);
      ctx.lineTo(this.x + this.length, this.y);
      ctx.stroke();
    }
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    
    const stars = [];
    const windLines = [];
    let animationFrameId;
    
    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    
    const initStars = () => {
      const starRegionHeight = canvas.height * 0.4;
      const numberOfStars = Math.floor((canvas.width * starRegionHeight) / 2000);
      
      for (let i = 0; i < numberOfStars; i++) {
        const x = Math.random() * canvas.width;
        const y = Math.random() * starRegionHeight;
        const size = 0.5 + Math.random() * 1.5;
        const brightness = 0.2 + Math.random() * 0.8;
        stars.push(new Star(x, y, size, brightness));
      }
    };

    const initWindLines = () => {
      const windRegionHeight = canvas.height * 0.6;
      const numberOfLines = Math.floor((canvas.width * windRegionHeight) / 2000);
      
      for (let i = 0; i < numberOfLines; i++) {
        const x = Math.random() * canvas.width;
        const y = canvas.height * 0.6 + Math.random() * windRegionHeight;
        const length = 20 + Math.random() * 30;
        const speed = 1.5 + Math.random() * 1.5;
        windLines.push(new WindLine(x, y, length, speed));
      }
    };


    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
      gradient.addColorStop(0, '#292929');    
      //gradient.addColorStop(0.35, '#292929'); 
      gradient.addColorStop(0.4, '#292929');  
      //gradient.addColorStop(0.45, '#7ed757'); 
      gradient.addColorStop(1, '#7ed757');    
      
      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      stars.forEach(star => {
        star.update();
        star.draw(ctx);
      });

      windLines.forEach(line => {
        line.update();
        line.draw(ctx);
      });


      animationFrameId = requestAnimationFrame(animate);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    initStars();
    initWindLines();
    animate();

    return () => {
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(animationFrameId);
    };
  });

  return (
    <canvas
      ref={canvasRef}
      className="fixed top-0 left-0 w-full h-full z-20"
    />
  );
};

export default WindNightSkyBackground;