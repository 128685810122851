import React from "react";
import WaterNightSkyBackground from "../../components/NightSkyBackgound/WaterNightSkyBackground";
import Header from "../../components/Header/Header";
import AboutMain from "../../components/About-Main/About-Main";
import Footer from "../../components/Footer/Footer";

function About () {
    return(
        <div className="About">
            <Header />
            <AboutMain />
            <WaterNightSkyBackground />
            <Footer />
        </div>
    )
}

export default About;